import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import styles from './ProductInfoDetails.module.scss';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { getFullProductDescription } from './services/get-full-product-description.service';
import './additional-styles.scss';
import { Image } from '../products-page/components/product-image-carousel/models';
import { downloadImage } from '../products-page/components/product-image-carousel/services';
import { getProductAttachments } from '../../shared/services/get-attachments.service';
import { useLocation, useParams } from 'react-router-dom';
import { getProducerAboutMe } from '../producer-details-view/services';
import { Producer } from '../products-page/components/producers-list/models';
import { Product } from '../products-page/models';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getProductsForCategoryService } from '../product-details-view/services';
import { decodeImageService } from '../../shared/services';
const certificateIcon = require("../../assets/icons/certificate-icon.svg").default;

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}



export default function ProductInfoDetails(props: any) {
    const { t, i18n } = useTranslation('translation');
    const [producerAboutMe, setProducerAboutMe] = useState<Producer>();
    const [value, setValue] = useState(0);
    const [fullProductDescription, setFullProductDescription] = useState("");
    const location = useLocation();
    const product = props.product;
    const [activeProduct, setActiveProduct] = useState<Product>();
    const { productId, categoryId } = useParams();
    const [products, setProducts] = useState<Product[]>([]);
    const filters = useSelector((state: RootState) => state.filters);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleDownloadClick = (certificateId: string, fileName: string) => {
        downloadImage(certificateId).then((response) => {
            const objectUrl = URL.createObjectURL(response.data);
            const downloadLink = document.createElement('a');
            downloadLink.href = objectUrl;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(objectUrl);
        });
    };

    const getProductsForCategory = async (): Promise<void> => {
        if (categoryId) {
            const response = await getProductsForCategoryService(categoryId, filters);
            if (response) {
                const fetchedProducts = response.data as Product[];
                setProducts(fetchedProducts);
                if (fetchedProducts && productId) {
                    const active = fetchedProducts.find(x => x.id === productId);
                    setActiveProduct(active);
                }
            }
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
        getProductsForCategory();
    }, [filters]);

    useEffect(() => {
        if (activeProduct?.contractorId) {
            getProducerAboutMe(activeProduct?.contractorId).then((response) => {
                const producerAboutMe = response.data as Producer;
                setProducerAboutMe(producerAboutMe);
            });
        }
    }, [activeProduct]);

    useEffect(() => {
        getFullProductDescription(props.productId).then((response: any) => {
            if (response?.data) {
                setFullProductDescription(response?.data);
            }
        });
    }, []);


    return (
        <div className={styles.main_container}>
            <div className={styles.line}></div>
            <div className={styles.tabs_container}>
                <Tabs TabIndicatorProps={{ className: styles.indicator }} value={value} onChange={handleChange} aria-label="basic tabs example">
                    {fullProductDescription !== "" && < Tab className={styles.tab} label={t("productDetails.fullDescription")} {...a11yProps(0)} />}
                    <Tab className={styles.tab} label={t("productDetails.producer")} {...a11yProps(1)} />
                    {producerAboutMe?.certificateDto.certificate && <Tab className={styles.tab} label={t("productDetails.certificates")} {...a11yProps(2)} />}
                </Tabs>
            </div>
            <div className={styles.tabs_content}>
                <TabPanel value={value} index={0}>
                    <>{parse(fullProductDescription !== "" && fullProductDescription.length > 0 ? fullProductDescription : "")} </>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div className={styles.producer_name}>{producerAboutMe?.name}</div>
                    <div>{parse(producerAboutMe?.aboutMeContent ? producerAboutMe?.aboutMeContent as string : "")}</div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <div className={styles.section_wrapper}>
                        {producerAboutMe?.certificateDto.certificate && (
                            <div className={styles.certificate_item}>
                                <img src={decodeImageService(producerAboutMe?.certificateDto.attachmentFile?.content as string, producerAboutMe?.certificateDto?.attachmentFile?.name as string)} />
                                <p className={styles.certificate_name}>{producerAboutMe.certificateDto?.certificate?.name}</p>
                                <p className={styles.certificate_by_name}>{producerAboutMe.certificateDto?.certificate?.certificatedByName}</p>
                                <div className={styles.certificate_content}>
                                    {parse(producerAboutMe?.certificateDto?.certificate?.description ? producerAboutMe?.certificateDto?.certificate?.description : "")}
                                </div>
                            </div>
                        )}
                    </div>
                </TabPanel>
            </div>
        </div>
    );
}
