import { useTranslation } from 'react-i18next'
import { decodeImageService } from '../../shared/services'
import styles from './ProducerCard.module.scss'
import { useNavigate } from 'react-router-dom';

export default function ProducerCard(props: any) {

    const { i18n, t } = useTranslation("translation");
    const navigate = useNavigate();

    const handleProductClick = () => {
        navigate("/producer-details-view/" + props.producer?.id);
    }

    return (
        <div className={styles.card_container}>
            <div className={styles.image_icons_container}>
                {props.producer?.logo?.content ? (
                    <img
                        src={decodeImageService(props.producer.logo.content, props.producer.logo.name)}
                        alt={props.producer.imageName}
                        onClick={handleProductClick}
                        className={styles.product_image}
                    />
                ) : (
                    <div className={styles.image_placeholder}></div>
                )}
            </div>

            <div className={styles.producer_name_container}>
                <h6 onClick={handleProductClick}>{props.producer?.name}</h6>
            </div>
            <button onClick={handleProductClick} className={styles.more_button}>{t("producer.card.more")}</button>
        </div>
    )
}
