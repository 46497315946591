import axios from "axios"
import { Cms, CmsUrlAndTitleDto, FormSetting } from "../models"

export const getCmsService = (cmsUrl: string): any => {
    return axios.get<Cms[]>(`Cms?cmsUrl=${cmsUrl}`);
}

export const getCmsByLanguageAndCode = (code: string, languageCode: string): any => {
    const url = `Cms/${code}/language/${languageCode}`;
    return axios.get<string>(url);
};

export const getCmsUrlAndTitleByLanguagAndCode = (code: string, languageCode: string): any => {
    const url = `Cms/urlAndTitle/${code}/language/${languageCode}`;
    return axios.get<CmsUrlAndTitleDto>(url);
};


export const getWebshopFooter = (languageCode: string): any => {
    const url = `Cms/webshopFooter/${languageCode}`;
    return axios.get<string>(url);
};

export const getFormSetting = (): any => {
    const url = 'Cms/formSetting';
    return axios.get<FormSetting>(url);
}
