import { ReactNode, useEffect, useState } from 'react';
import styles from './ProducerDetailsView.module.scss';
import { Producer } from '../products-page/components/producers-list/models';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { decodeImageService } from '../../shared/services';
import { Product } from '../products-page/models';
import { getProducerProducts } from './services';
import { ProductPaginated } from '../products-page/components/products-searched-view/models';
import { useTranslation } from 'react-i18next';
import './additional-styles.scss';
import { getProducerAboutMe } from './services/get-producer-about-me.service';
import OtherDistributorProducts from '../product-details-view/distributor-products/OtherDistributorProducts';
import PhotoGallery from '../../shared/ui-elements/photo-gallery/PhotoGallery';

export default function ProducerDetailsView(props: any) {
    const [producerAboutMe, setProducerAboutMe] = useState<Producer>();
    const [productsObject, setProductsObject] = useState<ProductPaginated>();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const { t, i18n } = useTranslation('translation');

    const { producerId } = useParams();
    const getProducerAboutMeContent = (): void => {
        if (producerId) {
            getProducerAboutMe(producerId).then((response) => {
                const producerAboutMe = response.data as Producer;
                setProducerAboutMe(producerAboutMe);
            });
        }
    }

    const getProducerProductsOnInit = (): void => {
        if (producerId) {
            getProducerProducts(currentPage + 1, perPage, producerId).then((response) => {
                const paginatedList = response?.data as ProductPaginated;
                setProductsObject(paginatedList);
            })
        }
    }

    const handleCartItemsChange = () => {
        props.onCartItemsChange();
    }

    useEffect(() => {
        getProducerProductsOnInit();
        getProducerAboutMeContent();
    }, []);

    useEffect(() => {
        getProducerProductsOnInit();
    }, [currentPage, perPage]);

    return (
        <div className={styles.main_container}>
            <div className={styles.producer_container}>
                <div className={styles.producer_picture}>
                    {producerAboutMe?.pictures && producerAboutMe.pictures.length > 0 && (
                        <PhotoGallery photos={producerAboutMe.pictures} />
                    )}
                </div>
                <div className={styles.producer_description}>
                    <div className={styles.text_container}>
                        <p className={styles.name}>
                            {producerAboutMe?.name.toUpperCase()}
                        </p>
                        {parse(producerAboutMe?.aboutMeContent ? producerAboutMe?.aboutMeContent : "")}</div>
                </div>
            </div>
            {producerAboutMe?.certificateDto.certificate && (
                <div className={styles.section_wrapper}>
                    <div className={styles.certificate_item}>
                        <p className={styles.name}>
                            {t("producerDetails.cards.producerCertificates")}
                        </p>
                        <div className={styles.certificate_details}>
                            <img src={decodeImageService(producerAboutMe?.certificateDto.attachmentFile?.content as string, producerAboutMe?.certificateDto?.attachmentFile?.name as string)} />
                            <div className={styles.certificate_description}>
                                <p className={styles.certificate_name}>{producerAboutMe.certificateDto?.certificate?.name}</p>
                                <p className={styles.certificate_by_name}>{producerAboutMe.certificateDto?.certificate?.certificatedByName}</p>
                                <div className={styles.certificate_content}>
                                    {parse(producerAboutMe?.certificateDto?.certificate?.description ? producerAboutMe?.certificateDto?.certificate?.description : "")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {productsObject && (
                <div className={styles.other_products}>
                    <div className={styles.cards_container}>
                        <OtherDistributorProducts productsObject={productsObject} onCartItemsChange={handleCartItemsChange} />
                    </div>
                </div>)
            }
        </div>

    )
}
