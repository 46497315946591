import { useEffect, useState } from 'react';
import styles from './Cart.module.scss';
import { getCartItems, removeCartItem, updateCartItemQuantity } from './services';
import { useTranslation } from 'react-i18next';
import { CartItem } from './models';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { calculateBrutto, decodeImageService, formatDateTimeService } from '../../shared/services';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getSellingSessionsPaginatedService } from '../selling-sessions/service';
import { SellingSession } from '../selling-sessions/models';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { usePluginStore } from 'react-pluggable';
import { PluginEvent } from '../../plugins/Extensions/pluginEvent';
import { checkLogInStatus } from '../../auth/services/auth.service';

const przelewy24Icon = require("../../assets/payments/przelewy24.jpg")

export default function Cart(props: any) {

    const currency = useSelector((state: RootState) => state.currency);
    const defaultValue = localStorage.getItem("pickupPointsFilter") ? localStorage.getItem("pickupPointsFilter")?.toString() : "any";
    const { t, i18n } = useTranslation('translation');
    const [cartItems, setCartItems] = useState<CartItem[]>();
    const navigate = useNavigate();
    const [sellingSessions, setSellingSessions] = useState<SellingSession[]>();
    const [selectedPickupPointId, setSelectedPickupPointId] = useState<string | null>(defaultValue as string);
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");

    useEffect(() => {
        fetchSellingSessions();
    }, [selectedPickupPointId]);

    useEffect(() => {
        pluginStore.addEventListener("SelectedPickupPointChanged", handlePickupPointChanged);
        return () => {
            pluginStore.removeEventListener("SelectedPickupPointChanged", handlePickupPointChanged);
        };
    }, [pluginStore])

    useEffect(() => {

        getCartItems().then((result) => {
            setCartItems(result as CartItem[])
        });
        window.scrollTo({ top: 0 });
    }, []);

    useEffect(() => {
        updateQuantityOfCartItemsOnInit();
    }, [cartItems])

    const fetchSellingSessions = () => {
        getSellingSessionsPaginatedService(1, 20, selectedPickupPointId).then((response: any) => {
            setSellingSessions(response.data?.items);
        });
    }

    const updateQuantityOfCartItemsOnInit = () => {
        if (cartItems) {
            cartItems.forEach((f) => {
                if (f.availableQuantity && f.quantity > f.availableQuantity) {
                    f.quantity = f.availableQuantity;
                }
            })
            localStorage.setItem('cart', JSON.stringify(cartItems));
        }
    }


    const handlePickupPointChanged = (pluginEvent: PluginEvent) => {
        const id = pluginEvent?.data?.id;
        setSelectedPickupPointId(id);
        fetchSellingSessions();
    }

    const removeItemFromCart = (itemId: string): void => {
        cartItems?.length === 1 && navigate("/");
        removeCartItem(itemId);
        props.onCartItemRemoved();

        getCartItems().then((result) => {
            setCartItems(result as CartItem[])
        });
    };

    const handleMinus = (id: string, quantity: number): void => {
        updateCartItemQuantity(id, false, quantity).then((cart) => {
            setCartItems(cart as CartItem[]);
            props.onCartItemsChange();
        });

    };

    const handlePlus = (id: string, quantity: number): void => {
        updateCartItemQuantity(id, true, quantity).then((cart) => {
            setCartItems(cart as CartItem[]);
            props.onCartItemsChange();
        });
    }

    const calculateTotalPriceWithVAT = (items: CartItem[] | undefined): string => {
        let sum = 0;
        items?.forEach(it => {
            sum += it.totalPrice
        });
        return sum.toFixed(2);
    }

    const calculateCartNettoWorth = (items: CartItem[] | undefined): string => {
        let sum = 0;
        items?.forEach(it => {
            sum += it.pricePerUnit * it.quantity;
        });
        return sum.toFixed(2);
    };


    const checkIfZeroQuantity = (items: CartItem[] | undefined): boolean => {
        if (items) {
            for (const element of items) {
                if (element.quantity === 0) {
                    return true;
                }
            }
        } else {
            return false;
        }
        return false;
    }

    const buyButtonAction = (items: CartItem[] | undefined) => {
        if (checkIfZeroQuantity(items)) {
            toast.error(t('cart.quantityError'));
        }
        else {
            if (checkLogInStatus()) {
                navigate("/order")
            }
            else {
                navigate("/continue-or-create-account")
            }
        }
    }


    const handleDoubleInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: CartItem) => {
        let inputValue = e.target.value.replace(",", ".");

        if (cartItems) {
            const updatedCartItems = [...cartItems];
            const index = updatedCartItems.findIndex(cartItem => cartItem.id === item.id);
            if (index !== -1 && !isNaN(parseFloat(inputValue)) && item.availableQuantity) {
                let parsedValue = parseFloat(inputValue);

                if (parsedValue > item.availableQuantity) {
                    parsedValue = item.availableQuantity
                } else if (parsedValue < 0) {
                    parsedValue = 0;
                }

                parsedValue = parseFloat(parsedValue.toFixed(2));

                updatedCartItems[index].quantity = parsedValue;
                updatedCartItems[index].totalPrice = parsedValue * updatedCartItems[index].pricePerUnit;
                setCartItems(updatedCartItems);
                localStorage.setItem('cart', JSON.stringify(updatedCartItems));
                props.onCartItemsChange();

            }
        }
    };

    const handleIntInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: CartItem) => {
        let inputValue = Number(e.target.value);
        if (cartItems) {
            const updatedCartItems = [...cartItems];
            const index = updatedCartItems.findIndex(cartItem => cartItem.id === item.id);
            if (index !== -1 && !isNaN(inputValue) && item.availableQuantity !== undefined) {
                if (inputValue > item.availableQuantity) {
                    updatedCartItems[index].quantity = item.availableQuantity;
                } else if (inputValue < 0) {
                    updatedCartItems[index].quantity = 0;
                }
                else {
                    updatedCartItems[index].quantity = inputValue;
                }
                updatedCartItems[index].totalPrice = inputValue * updatedCartItems[index].pricePerUnit;
                setCartItems(updatedCartItems);
                localStorage.setItem('cart', JSON.stringify(updatedCartItems));
                props.onCartItemsChange();
            }
        }
    }



    return (
        <>
            {window.innerWidth >= 1070 ?
                <>
                    <div className={styles.cart_container}>
                        <div className={styles.up_decor}></div>
                        {cartItems?.length == 0 ?
                            <div className={styles.main_container}>
                                <div className={styles.empty_cart}>
                                    <h2 className={styles.cart_title}>Koszyk jest pusty!</h2>
                                    <div className={styles.buy_button} onClick={() => navigate("/")}>
                                        {t("cart.returnToShopping")}
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={styles.main_container}>
                                <div className={styles.table_container}>

                                    <h2 className={styles.cart_title}>{t("cart.contentsBasket")}</h2>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className={styles.product}>{t("cart.product")}</th>
                                                <th className={styles.price}>{t("cart.price")}</th>
                                                <th className={styles.quantity}>{t("cart.quantity")}</th>
                                                <th className={styles.totalPrice}>{t("cart.totalPrice")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cartItems && cartItems?.map((item: CartItem, index: number) => (
                                                    <tr key={index}>
                                                        <td className={styles.product_column}>
                                                            <CloseIcon className={styles.cross_icon} fontSize='small' onClick={() => removeItemFromCart(item?.id)} />
                                                            <div className={styles.image_container}>
                                                                <img src={decodeImageService(item?.imageContent, item?.imageName)} alt={item?.imageName} />
                                                            </div>
                                                            <a href={`product-details/${item?.productId}/${item?.parentProductId}`}>{item.name}</a>
                                                        </td>
                                                        <td className={styles.price_column}>
                                                            {currency + " " + calculateBrutto(item?.pricePerUnit, item.vat)}
                                                        </td>
                                                        <td className={styles.quantity_column}>
                                                            <div className={styles.wrapper}>
                                                                <div
                                                                    className={((item?.availableQuantity as number > 0) && (item.quantity !== 0)) ? styles.minus_button : `${styles.minus_button} ${styles.inactive}`}
                                                                    onClick={() => handleMinus(item?.id, item?.isQuantityNaturalNumber ? 1 : 0.5)}>
                                                                    -</div>
                                                                <input
                                                                    type={item.isQuantityNaturalNumber ? 'text' : 'number'}
                                                                    className={styles.amount}
                                                                    value={String(item.quantity)}
                                                                    onChange={item.isQuantityNaturalNumber ? (event) => handleIntInputChange(event, item)
                                                                        : (event) => handleDoubleInputChange(event, item)
                                                                    }
                                                                    min={0}
                                                                    step="any"
                                                                />
                                                                <div>
                                                                    {item.saleUnitName}
                                                                </div>
                                                                <div
                                                                    className={((item?.availableQuantity as number > 0) && (item.quantity < (item?.availableQuantity as number))) ? styles.plus_button : `${styles.plus_button} ${styles.inactive}`}
                                                                    onClick={() => handlePlus(item?.id, item?.isQuantityNaturalNumber ? 1 : 0.5)}>+</div>
                                                            </div>
                                                        </td>
                                                        <td className={styles.totalPrice_column}>
                                                            {currency + " " + item.totalPrice.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    <Renderer placement={"page:selectSharedPickupPoint"} />

                                    {sellingSessions && sellingSessions.length > 0 && (
                                        <>
                                            <h4>{t("sellingSessions.header")}</h4>
                                            <table className={styles.selling_sessions_table}>
                                                <thead>
                                                    <tr>
                                                        <th className={styles.align_left}>{t("sellingSessions.table.name")}</th>
                                                        <th>{t("sellingSessions.table.startOrderingDate")}</th>
                                                        <th>{t("sellingSessions.table.endOrderingDate")}</th>
                                                        <th>{t("sellingSessions.table.startPickupDate")}</th>
                                                        <th>{t("sellingSessions.table.endPickupDate")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        sellingSessions && sellingSessions?.map((ss: SellingSession, index: number) => (
                                                            <tr key={uuid()}>
                                                                <td className={styles.centered_td}>{ss.name}</td>
                                                                <td className={styles.centered_td}>{formatDateTimeService(ss.startOrderingDate)}</td>
                                                                <td className={styles.centered_td}>{formatDateTimeService(ss.endOrderingDate)}</td>
                                                                <td className={styles.centered_td}>{formatDateTimeService(ss.startPickupDate)}</td>
                                                                <td className={styles.centered_td}>{formatDateTimeService(ss.endPickupDate)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </>
                                    )
                                    }
                                </div>
                                <div className={styles.side_block}>
                                    <h2 className={styles.cart_title}>{t("cart.summaryCard")}</h2>
                                    <div className={styles.products_price}>
                                        <p className={styles.label}>{t("cart.productsPrice")}</p>
                                        <p className={styles.number}>{calculateTotalPriceWithVAT(cartItems) + " " + currency}</p>
                                    </div>
                                    <div className={styles.total_with_VAT}>
                                        <p className={styles.label}>{t("cart.totalPrice")}</p>
                                        <p className={styles.total}>{calculateTotalPriceWithVAT(cartItems) + " " + currency}</p>
                                    </div>
                                    <div className={styles.return_button} onClick={() => navigate("/")}>
                                        {t("cart.returnToShopping")}
                                    </div>
                                    <button className={styles.buy_button} onClick={() => { buyButtonAction(cartItems) }}>
                                        {t("cart.buyAndPay")}
                                    </button>
                                </div>
                            </div>
                        }
                    </div>

                </>
                :
                <>
                    <div className={styles.small_device_container}>
                        <div className={styles.decor}></div>
                        <div className={styles.pickup_points_container}>
                            <div className={styles.label}>{t("sellingSessions.label")}</div>
                            <div className={styles.select_wrapper}>
                                <Renderer placement={"page:selectPickupPoint"} />
                            </div>
                        </div>
                        <div className={styles.small_device_main_container}>
                            {
                                cartItems && cartItems?.map((item: CartItem, index: number) => (
                                    <div key={index} className={styles.item_wrapper}>
                                        <div className={styles.product_info}>
                                            <CloseIcon className={styles.cross_icon} fontSize='small' onClick={() => removeItemFromCart(item?.id)} />
                                            <div className={styles.image_container}>
                                                <img src={decodeImageService(item?.imageContent, item?.imageName)} alt={item?.imageName} />
                                            </div>
                                            <a href={`product-details/${item?.productId}/${item?.parentProductId}`}><p>{item.name}</p></a>
                                        </div>
                                        <div>
                                            <div className={styles.price}>
                                                <p>{currency + " " + item.pricePerUnit?.toFixed(2)}</p>
                                            </div>
                                            <div className={styles.quantity}>
                                                <div className={styles.wrapper}>
                                                    <div className={styles.minus_button} onClick={() => handleMinus(item?.id, item?.isQuantityNaturalNumber ? 1 : 0.5)}><p>-</p></div>
                                                    <input
                                                        type={item.isQuantityNaturalNumber ? 'text' : 'number'}
                                                        className={styles.amount}
                                                        value={String(item.quantity)}
                                                        onChange={item.isQuantityNaturalNumber ? (event) => handleIntInputChange(event, item)
                                                            : (event) => handleDoubleInputChange(event, item)
                                                        }
                                                        min={0}
                                                        step="any"
                                                    />
                                                    <div className={styles.plus_button} onClick={() => handlePlus(item?.id, item?.isQuantityNaturalNumber ? 1 : 0.5)}><p>+</p></div>
                                                </div>
                                            </div>
                                            <div className={styles.totalPrice}>
                                                <p>{t("cart.totalPrice")}</p>
                                                <p className={styles.amount}>{currency + " " + item.totalPrice.toFixed(2)}</p>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                            <table className={styles.selling_sessions_table}>
                                <thead>
                                    <tr>
                                        <th>{t("sellingSessions.table.name")}</th>
                                        <th>{t("sellingSessions.table.startOrderingDate")}</th>
                                        <th>{t("sellingSessions.table.endOrderingDate")}</th>
                                        <th>{t("sellingSessions.table.startPickupDate")}</th>
                                        <th>{t("sellingSessions.table.endPickupDate")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sellingSessions && sellingSessions?.map((ss: SellingSession, index: number) => (
                                            <tr key={uuid()}>
                                                <td className={styles.centered_td}>{ss.name}</td>
                                                <td className={styles.centered_td}>{formatDateTimeService(ss.startOrderingDate)}</td>
                                                <td className={styles.centered_td}>{formatDateTimeService(ss.endOrderingDate)}</td>
                                                <td className={styles.centered_td}>{formatDateTimeService(ss.startPickupDate)}</td>
                                                <td className={styles.centered_td}>{formatDateTimeService(ss.endPickupDate)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <div className={styles.side_block_small_device}>
                                <div className={styles.products_price}>
                                    <p className={styles.label}>{t("cart.productsPrice")}</p>
                                    <p className={styles.number}>{currency + " " + calculateCartNettoWorth(cartItems)}</p>
                                </div>
                                <div className={styles.total_with_VAT}>
                                    <p className={styles.label}>{t("cart.totalCostWithVAT")}</p>
                                    <p className={styles.total}>{currency + " " + calculateTotalPriceWithVAT(cartItems)}</p>
                                </div>
                                <div className={styles.buy_button} onClick={() => navigate("/order")}>
                                    <p>{t("cart.buyAndPay")}</p>
                                </div>
                                <div className={styles.payments}>
                                    <p className={styles.label}>{t("cart.payments")}</p>
                                    <img src={przelewy24Icon} alt="Przelewy24" />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
