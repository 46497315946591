import { useEffect, useState } from 'react';
import { Przelewy24Payment } from '../order/models/przelewy24-payment.model';
import styles from './Przelewy24PaymentPage.module.scss';
import { getPrzelewy24PaymentMethods, registerPrzelewy24Transaction } from '../payment/services/przelewy24.service';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../store/store';
import { DeliveryMethod, PaymentMethod } from '../order/models';
import { getPayments, getDeliveries } from '../order/services';
import { OrderDetailsModel } from '../order/models/order-details.model';
import { getOrderDetails } from '../client-panel/components/my-account-info/services';
import { RequestRegisterPaymentDto } from '../payment/models/request-register-payment.dto';
import { PRZELEWY24_PAYMENT_METHOD } from '../payment/online-payments.constants';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

export default function Przelewy24PaymentPage() {
    const currency = useSelector((state: RootState) => state.currency);
    const [orderDetails, setOrderDetails] = useState<OrderDetailsModel | null>();
    const [onlinePaymentDto, setOnlinePaymentDto] = useState<RequestRegisterPaymentDto>();
    const location = useLocation();
    const navigate = useNavigate();
    const { orderId } = useParams();
    const { t, i18n } = useTranslation('translation');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>();
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethod[]>([]);
    const [przelewy24PaymentMethods, setPrzelewy24PaymentMethods] = useState<Przelewy24Payment[]>([]);
    const [selectedPrzelewy24PaymentMethod, setSelectedPrzelewy24PaymentMethod] = useState<Przelewy24Payment | null>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPaymentMethodsOnOnit();
        getDeliveryMethodsOnOnit();
        if (orderId) getOrderData(orderId)
        fetchPrzelewy24PaymentMethods();
    }, [orderId]);

    useEffect(() => {
        if (orderDetails) {
            setPaymentMethod(orderDetails.order?.paymentMethod);
        }
    }, [orderDetails]);

    const getPaymentMethodsOnOnit = (): void => {
        getPayments().then((response) => {
            if (response) {
                setPaymentMethods(response.data as PaymentMethod[]);
                setLoading(false);
            }
        })
    };

    const getDeliveryMethodsOnOnit = (): void => {
        getDeliveries().then((response) => {
            if (response) {
                setDeliveryMethods(response.data as DeliveryMethod[])
            }
        })
    };

    const getOrderData = (orderId: string | null): void => {
        if (orderId) {
            getOrderDetails(orderId).then((response) => {
                if (response?.data) {
                    setOrderDetails(response?.data as OrderDetailsModel)
                    setOnlinePaymentDto({
                        email: response.data.userDetails.user.email,
                        sessionId: response.data.order.onlinePaymentSessionId,
                        orderIntegerId: response.data.order.integerId,
                        urlReturn: "/",
                        orderId: response.data?.order?.id
                    });
                    setPaymentMethod(response?.data?.order?.paymentMethod);
                }
            });
        }
    }

    const setPaymentMethod = (id: string) => {
        const method = paymentMethods.find(x => x.id == id);
        if (method) {
            setSelectedPaymentMethod(method);
        }
    };

    const handlePrzelewy24PaymentMethodChange = (przelewy24PaymentMethod: Przelewy24Payment) => {
        if (onlinePaymentDto) onlinePaymentDto.onlinePaymentMethodId = przelewy24PaymentMethod?.id;
        setSelectedPrzelewy24PaymentMethod(przelewy24PaymentMethod);
    };

    const fetchPrzelewy24PaymentMethods = async () => {
        const response = await getPrzelewy24PaymentMethods(currency, Number(orderDetails?.order?.totalPrice), localStorage.getItem('lang')!);
        const paymentMethods = Array.isArray(response) ? response : [response];

        const updatedPaymentMethods = paymentMethods.map(async (paymentMethod) => {
            try {
                const response = await fetch(paymentMethod.imgUrl);
                const imgExist = response.status === 200;
                return { ...paymentMethod, imgExist };
            } catch (error) {
                return { ...paymentMethod, imgExist: false };
            }
        });
        const updatedPaymentMethodsData = await Promise.all(updatedPaymentMethods);
        setPrzelewy24PaymentMethods(updatedPaymentMethodsData);
    };

    const handleRegisterTransaction = async () => {
        if (onlinePaymentDto) {
            const transactionUrl = await registerPrzelewy24Transaction(onlinePaymentDto);
            window.location.href = transactionUrl;
        }
    }

    return (
        <div className={styles.przelewy24_select_wrapper}>
            <h1>{t("orderConfirmation.onlinePayment.chooseBank")}</h1>
            {loading && <CircularProgress className={styles.loading_spinner} color="success" size={'100px'} />}
            {selectedPaymentMethod?.code === PRZELEWY24_PAYMENT_METHOD && !loading && (
                <div className={styles.grid_container}>
                    {przelewy24PaymentMethods && przelewy24PaymentMethods?.map((przelewy24PaymentMethod) => (
                        <div
                            key={przelewy24PaymentMethod.id}
                            className={`${styles.grid_item} ${selectedPrzelewy24PaymentMethod?.id === przelewy24PaymentMethod.id ? styles.selected : styles.disabled}`}
                            onClick={() => handlePrzelewy24PaymentMethodChange(przelewy24PaymentMethod)}
                        >
                            {przelewy24PaymentMethod && (
                                przelewy24PaymentMethod?.imgExist ? (
                                    <img src={przelewy24PaymentMethod.imgUrl} alt="Przelewy24 logo" />
                                ) : (
                                    <div>{przelewy24PaymentMethod?.name}</div>
                                )
                            )}
                        </div>
                    ))}
                </div>
            )}
            <div className={`${styles.button} ${selectedPrzelewy24PaymentMethod?.id ? styles.selected : styles.disabled}`} onClick={() => handleRegisterTransaction()}>
                {t("orderConfirmation.summary.pay")}
            </div>
        </div>
    )

}