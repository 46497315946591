import { t } from 'i18next';
import { Country, OrderModel } from '../../models';
import styles from './ClientData.module.scss';
import { useEffect, useState } from 'react';
import { getCountries } from '../../services';
import { PickupPoint } from '../../../../shared/map/models';

interface ClientDataProps {
    orderModel: OrderModel;
    pickupPointMethodChecked: boolean;
}

export default function ClientData({ orderModel, pickupPointMethodChecked }: ClientDataProps) {
    const [countries, setCountries] = useState<Country[]>([]);

    useEffect(() => {
        getCountries().then((response) => {
            if (response.data) {
                setCountries(response.data as Country[]);
            }
        });
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <div className={styles.header}>DANE ZAMAWIAJĄCEGO</div>
                <div className={styles.rows}>
                    <div className={styles.first_row}>
                        <div>{orderModel.userFirstName} {orderModel.userLastName}</div>
                    </div>
                    <div className={styles.third_row}>
                        <div>
                            {orderModel.userAddress.streetName} {orderModel.userAddress.buildingNumber}{orderModel.userAddress.flatNumber !== '' && `/${orderModel.userAddress.flatNumber}`}
                        </div>
                        <div>{orderModel.userAddress.postalCode} {orderModel.userAddress.city}</div>
                        <div>{t(`countriesNames.${countries?.find(x => x.id === orderModel.userAddress.country)?.name}`)}</div>
                    </div>
                    <div className={styles.second_row}>
                        <div>{orderModel.userEmail}</div>
                        <div>{orderModel.userPhoneNumber}</div>
                    </div>
                </div>
            </div>
            {!pickupPointMethodChecked &&
                <>
                    {orderModel.orderAddress && orderModel.orderAddress.streetName ?
                        <div className={styles.column}>
                            <div className={styles.header}>ADRES DOSTAWY</div>
                            <div className={styles.rows}>
                                <div className={styles.first_row}>
                                    <div>{orderModel.userFirstName} {orderModel.userLastName}</div>
                                </div>
                                <div className={styles.third_row}>
                                    <div>
                                        {orderModel.orderAddress.streetName} {orderModel.orderAddress.buildingNumber}{orderModel.orderAddress.flatNumber !== '' && `/${orderModel.orderAddress.flatNumber}`}
                                    </div>
                                    <div>{orderModel.orderAddress.postalCode} {orderModel.orderAddress.city}</div>
                                    <div>{t(`countriesNames.${countries?.find(x => x.id === orderModel.orderAddress?.country)?.name}`)}</div>
                                </div>
                                <div className={styles.second_row}>
                                    <div>{orderModel.userEmail}</div>
                                    <div>{orderModel.userPhoneNumber}</div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={styles.column}>
                            <div className={styles.header}>ADRES DOSTAWY</div>
                            <div className={styles.rows}>
                                <div className={styles.first_row}>
                                    <div>{orderModel.userFirstName} {orderModel.userLastName}</div>
                                </div>
                                <div className={styles.third_row}>
                                    <div>
                                        {orderModel.userAddress.streetName} {orderModel.userAddress.buildingNumber}{orderModel.userAddress.flatNumber !== '' && `/${orderModel.userAddress.flatNumber}`}
                                    </div>
                                    <div>{orderModel.userAddress.postalCode} {orderModel.userAddress.city}</div>
                                    <div>{t(`countriesNames.${countries?.find(x => x.id === orderModel.userAddress.country)?.name}`)}</div>
                                </div>
                                <div className={styles.second_row}>
                                    <div>{orderModel.userEmail}</div>
                                    <div>{orderModel.userPhoneNumber}</div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
}
