import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { PickupPoint } from '../../../shared/map/models';
import { MenuItem, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import styles from "./PickupPointPanel.module.scss";

export default function PickupPointPanel() {
    const { productId } = useParams();
    const productsIdsWithPickupPoints = useSelector((state: RootState) => state.productsIdsWithPickupPoints);
    const [pickupPoints, setPickupPoints] = useState<PickupPoint[] | undefined>([]);

    useEffect(() => {
        setPickupPoints(productsIdsWithPickupPoints?.find((obj: any) => obj.productId === productId)?.pickupPoints);
    }, [productId, productsIdsWithPickupPoints]);
    return (
        <div className={styles.container}>
            {pickupPoints && pickupPoints?.map((pickupPoint: PickupPoint) => (
                <MenuItem key={pickupPoint?.id} value={pickupPoint?.id} className={styles.menuItem}>
                    <Typography variant="subtitle2">{pickupPoint?.name}</Typography>
                    <Typography variant="body2" color="textSecondary" className={styles.pickupPointAddress}>
                        {pickupPoint?.address?.streetName + " " + pickupPoint?.address?.buildingNumber}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" className={styles.pickupPointAddress} >
                        {pickupPoint?.address?.postalCode + " " + pickupPoint?.address?.city}
                    </Typography>
                </MenuItem>
            ))}
        </div>
    );
}