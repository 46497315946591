import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './MyAccountInfo.module.scss';
import { useTranslation } from 'react-i18next';
import * as userDetailsService from './services/user-details.service';
import { UserData } from './models';
import axios from 'axios';
import { Country, OrderModel } from '../../../order/models';
import { CartItem } from '../../../cart/models';
import { getUserOrders } from './services';
import dayjs from 'dayjs';
import { Address } from './models/address.model';


function getUserDetails() {
    return userDetailsService.getUserDetails();
}

export default function MyAccountInfo() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('translation');

    const [userDetails, setUserDetails] = useState<UserData>();
    const [countries, setCountries] = useState<Country[]>();
    const [mainAddress, setMainAddress] = useState<Address>();

    useEffect(() => {
        getUserDetails().then((response) => {
            if (response) {
                setUserDetails(response.data);
            } else {
                navigate("../", { replace: false });
            }
        });
        axios.get("countries").then((response) => {
            if (response) {
                setCountries(response?.data as Country[]);
            }
        })
    }, []);

    useEffect(() => {
        const mainAddress = userDetails?.address?.find(address => address.addressType === 'MAIN');
        if (mainAddress) {
            setMainAddress(mainAddress);
        }
    }, [userDetails]);

    return (
        <div className={styles.user_details_container}>
            <p className={styles.title}>{t("myAccountInfo.title")}</p>
            <div className={styles.user_data_container}>
                <div className={styles.section}>
                    <p className={styles.section_title}>{t("myAccountInfo.userDetails.title")}</p>
                    <p className={styles.section_subtitle}>{t("myAccountInfo.personalData.title")}</p>
                    <div className={styles.section_data}>
                        <div className={styles.row}>
                            <p className={styles.data_name}>{t("myAccountInfo.personalData.firstName")}:</p>
                            <p className={styles.data_value}>{userDetails?.user?.firstName}</p>
                        </div>
                        <div className={styles.row}>
                            <p className={styles.data_name}>{t("myAccountInfo.personalData.lastName")}:</p>
                            <p className={styles.data_value}>{userDetails?.user?.lastName}</p>
                        </div>
                        <div className={styles.row}>
                            <p className={styles.data_name}>{t("myAccountInfo.personalData.email")}:</p>
                            <p className={styles.data_value}>{userDetails?.user?.email}</p>
                        </div>
                    </div>
                </div>

                <p className={styles.section_title}>{t("myAccountInfo.addressesBook.title")}</p>
                <div className={styles.double_section}>
                    <div className={styles.section}>
                        <p className={styles.section_subtitle}>{t("myAccountInfo.address.title")}</p>
                        <div className={styles.section_data}>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.address.addressType")}:</p>
                                {mainAddress && mainAddress.addressType ? (
                                    <p className={styles.data_value}>
                                        {t("myAccountInfo.address." + mainAddress.addressType.toLowerCase())}
                                    </p>
                                ) : null}
                            </div>

                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.address.country")}:</p>
                                <p className={styles.data_value}>{t(`countriesNames.${countries?.find(x => x.id === mainAddress?.country)?.name}`)}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.address.city")}:</p>
                                <p className={styles.data_value}>{mainAddress?.city}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.address.postalCode")}:</p>
                                <p className={styles.data_value}>{mainAddress?.postalCode}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.address.streetName")}:</p>
                                <p className={styles.data_value}>{mainAddress?.streetName}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.address.buildingNumber")}:</p>
                                <p className={styles.data_value}>{mainAddress?.buildingNumber}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.address.flatNumber")}:</p>
                                <p className={styles.data_value}>{mainAddress?.flatNumber}</p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.section}>
                        <p className={styles.section_subtitle}>{t("myAccountInfo.contact.title")}</p>
                        <div className={styles.section_data}>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.contact._title")}:</p>
                                <p className={styles.data_value}>{userDetails?.contact?.title ? t("myAccountInfo.contact." + userDetails?.contact?.title?.toLowerCase()) : ""}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.contact.firstName")}:</p>
                                <p className={styles.data_value}>{userDetails?.contact?.firstName}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.contact.lastName")}:</p>
                                <p className={styles.data_value}>{userDetails?.contact?.lastName}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.contact.phone")}:</p>
                                <p className={styles.data_value}>{userDetails?.contact?.phone}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.contact.mobile")}:</p>
                                <p className={styles.data_value}>{userDetails?.contact?.mobile}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.contact.email")}:</p>
                                <p className={styles.data_value}>{userDetails?.contact?.email}</p>
                            </div>
                            <div className={styles.row}>
                                <p className={styles.data_name}>{t("myAccountInfo.contact.website")}:</p>
                                <p className={styles.data_value}>{userDetails?.contact?.website}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
