import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import { PickupPoint } from '../../../../shared/map/models';
import styles from './PickupPointSelectionPanel.module.scss';
import { SellingSession } from '../../../selling-sessions/models';
import { PickupPointWithSellingSessionsDto } from '../../models/PickupPointWithSellingSessionsDto.model';

interface PickupPointSelectionPanelProps {
    pickupPointsWithSellingSessions: PickupPointWithSellingSessionsDto[];
    selectPickupPoint: any;
}

export default function PickupPointSelectionPanel({ pickupPointsWithSellingSessions, selectPickupPoint }: PickupPointSelectionPanelProps) {

    const [selectedPickupPoint, setSelectedPickupPoint] = useState<PickupPoint>();

    const handlePickupPointClick = (pickupPoint: PickupPoint) => {
        setSelectedPickupPoint(pickupPoint);
    };

    useEffect(() => {
        selectPickupPoint(selectedPickupPoint);
    }, [selectedPickupPoint]);

    const checkIfIsSelected = (pickupPoint: PickupPoint) => pickupPoint.id === selectedPickupPoint?.id;

    return (
        <div className={styles.container}>
            {pickupPointsWithSellingSessions.map((pickupPointWithSessions, index) => (
                <div key={index} className={`${styles.single_point_panel} ${checkIfIsSelected(pickupPointWithSessions.pickupPointToReturn) ? styles.selected_pickup_point : ''}`}
                    onClick={() => { handlePickupPointClick(pickupPointWithSessions.pickupPointToReturn) }}>
                    <div className={styles.pp_header}>{`Punkt odbioru ${index + 1}`}</div>
                    <div className={styles.row}>
                        <div className={styles.pp_name}>{pickupPointWithSessions.pickupPointToReturn.name}</div>
                        <div className={styles.pp_address}>{pickupPointWithSessions.pickupPointToReturn.address.streetName} {pickupPointWithSessions.pickupPointToReturn.address.buildingNumber}</div>
                        <div className={styles.pp_address}>{pickupPointWithSessions.pickupPointToReturn.address.postalCode} {pickupPointWithSessions.pickupPointToReturn.address.city}</div>
                    </div>
                    {pickupPointWithSessions.sellingSessionInfos.length > 0 && pickupPointWithSessions.sellingSessionInfos.map((s, sessionIndex) => {
                        const startOrderingDate = new Date(s.startOrderingDate);
                        const endOrderingDate = new Date(s.endOrderingDate);
                        const startPickupDate = new Date(s.startPickupDate);
                        const endPickupDate = new Date(s.endPickupDate);

                        return (
                            <div className={styles.sessions_container} key={sessionIndex}>
                                <div className={styles.row}>
                                    <div className={styles.pp_header}>Składanie zamówienia</div>
                                    <div className={styles.pp_name}>od {startOrderingDate.toLocaleDateString()} {startOrderingDate.toLocaleTimeString()}</div>
                                    <div className={styles.pp_name}>do {endOrderingDate.toLocaleDateString()} {endOrderingDate.toLocaleTimeString()}</div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.pp_header}>Możliwy odbiór</div>
                                    <div className={styles.pp_name}>od {startPickupDate.toLocaleDateString()} {startPickupDate.toLocaleTimeString()}</div>
                                    <div className={styles.pp_name}>do {endPickupDate.toLocaleDateString()} {endPickupDate.toLocaleTimeString()}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
}
