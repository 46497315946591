import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Search.module.scss';
import { Category } from '../products-categories/models/Category.model';
import axios from 'axios';
import { RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Filters } from '../../../../models/filters.model';
import { setFilters } from '../../../../store/actions';

export default function Search(props: any) {
    const productFilters = useSelector((state: RootState) => state.filters);
    const dispatch = useDispatch();

    const magnifierIcon = require("../../../../assets/icons/magnifier_white.svg").default;
    const [mainCategories, setMainCategories] = useState<Category[]>([]);
    const { t, i18n } = useTranslation('translation');
    const [searchCategoryId, setSearchCategoryId] = useState<string | null>(null);
    const [searchProductName, setSearchProductName] = useState('');
    const [sortBy, setSortBy] = useState<string | null>(null);
    const guidEmpty = "00000000-0000-0000-0000-000000000000";

    // const handleCartItemsChange = () => {
    //     props.onCartItemsChange();
    // }

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`categories/tree/${guidEmpty}`);
                const categories: Category[] = response?.data;
                setMainCategories(categories);
            } catch (error) {
            }
        };
        fetchCategories();
    }, []);

    const handleSearchClick = () => {
        const filters: Filters = {
            pickupPointId: productFilters.pickupPointId,
            categoryId: searchCategoryId,
            productName: searchProductName,
            sortBy: sortBy
        }
        if (filters.categoryId == "") {
            filters.categoryId = null;
        }
        if (filters.sortBy === "") {
            filters.sortBy = null
        }
        dispatch(setFilters(filters));
        if (props.searchedClicked) {
            props.searchedClicked();
        }
    }

    const handleCategorySelect = (event: any) => {
        const value = event.target.value;
        setSearchCategoryId(value);
    }

    const handleSearchPhraseChange = (event: any) => {
        setSearchProductName(event.target.value);
    }

    const handleSortChange = (event: any): void => {
        const sortBy = event.target.value;
        setSortBy(sortBy);
    }

    return (
        <div className={styles.search_and_filter_wrapper}>
            <div className={styles.search_wrapper}>
                <select onChange={handleCategorySelect} name="" id="" value={searchCategoryId || searchCategoryId === "" ? searchCategoryId : productFilters.categoryId ? productFilters.categoryId as string : ""}>
                    <option value={""}>Wszystko</option>
                    {mainCategories && mainCategories?.map((category: Category, index: number) => (
                        <option key={index} value={category.id} >{t(category.name)}</option>
                    ))}
                </select>
                <input type="text" placeholder='...' onChange={handleSearchPhraseChange} defaultValue={productFilters.productName ? productFilters.productName as string : ""} />
                <button onClick={handleSearchClick}><img src={magnifierIcon} alt="Magnifier" /></button>
            </div>
        </div>
    );
}