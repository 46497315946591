import axios from "axios";
import { ProductImage } from "../../../shared/product-card/models/product-image.model";
import { Product } from "../../products-page/models";
import { CartItem } from "../models";
import { PickupPoint } from "../../../shared/map/models";
import uuid from "react-uuid";
import { calculateBrutto } from "../../../shared/services";

export const getCartItems = () => {
    return new Promise((resolve, reject) => {
        if (localStorage.getItem("cart")) {
            var cart = JSON.parse(localStorage.getItem("cart") as string);
            Array.isArray(cart) && resolve(cart);
            return;
        }
        resolve(null);
    })
}

export const clearCart = () => {
    return new Promise((resolve, reject) => {
        resolve(localStorage.removeItem("cart"));
    })
}

export const addToCart = (product: Product, quantity: number, image: ProductImage) => {
    return new Promise((resolve, reject) => {
        if (!localStorage.getItem("cart")) {
            localStorage.setItem("cart", JSON.stringify([]));
        }
        let cart = JSON.parse(localStorage.getItem("cart") as string);
        if (Array.isArray(cart)) {
            if (cart.find(x => x.productId === product.id)) {
                let item = cart?.find(x => x.productId === product?.id);
                item.quantity = item.quantity + quantity;
                item.totalPrice = Number(calculateBrutto(product.price, product.vat)) * item.quantity;
                item.availableQuantity = product.numberOfItems;
                resolve(localStorage.setItem("cart", JSON.stringify(cart)));
            } else {
                Array.isArray(cart) && cart.push({
                    id: uuid(),
                    productId: product.id,
                    name: product.name,
                    quantity: quantity,
                    pricePerUnit: product.price,
                    availableQuantity: product.numberOfItems,
                    vat: product.vat,
                    totalPrice: Number(calculateBrutto(product.price, product.vat)) * quantity,
                    imageName: image?.name,
                    imageContent: image?.content,
                    parentProductId: product.parentProductId,
                    saleUnitName: product.saleUnitName,
                    isQuantityNaturalNumber: product.isQuantityNaturalNumber
                } as CartItem);
                resolve(localStorage.setItem("cart", JSON.stringify(cart)));
            }
        }

    });
}

export const removeCartItem = (id: string) => {
    return new Promise((resolve, reject) => {
        if (!localStorage.getItem("cart")) {
            return;
        }
        let cart = JSON.parse(localStorage.getItem("cart") as string);
        cart = Array.isArray(cart) && cart.filter(x => x.id !== id);
        resolve(localStorage.setItem("cart", JSON.stringify(cart)));
    })
}

export const updateCartItemQuantity = (id: string, increase: boolean, quantity: number) => {
    return new Promise((resolve, reject) => {
        if (!localStorage.getItem("cart")) {
            return;
        }
        let cart = JSON.parse(localStorage.getItem("cart") as string);
        let cartItem = Array.isArray(cart) && cart.find(x => x.id === id);
        let cartItemIndex = cart.indexOf(cartItem) as number;
        increase ?
            cart[cartItemIndex].quantity < cart[cartItemIndex].availableQuantity ? cart[cartItemIndex].quantity += quantity : cart[cartItemIndex].quantity = cart[cartItemIndex].availableQuantity
            : cart[cartItemIndex].quantity > 0 ? cart[cartItemIndex].quantity -= quantity : cart[cartItemIndex].quantity = 0;
        cart[cartItemIndex].totalPrice = Number(calculateBrutto(cart[cartItemIndex].pricePerUnit, cart[cartItemIndex].vat)) * cart[cartItemIndex].quantity;
        localStorage.setItem("cart", JSON.stringify(cart));
        resolve(JSON.parse(localStorage.getItem("cart") as string));
    })
}
