import React from 'react';
import styles from './PickupMethods.module.scss';
import { useTranslation } from 'react-i18next';

const pickupPersonallyIcon = require('../../assets/img/food-delivery.svg').default;
const glovoIcon = require('../../assets/img/glovo.svg').default;
const uberEatsIcon = require('../../assets/img/uber-eats.svg').default;

export default function PickupMethods() {
    const { t, i18n } = useTranslation('translation')

    return (
        <div className={styles.main_container}>
            <div className={styles.title}>
                {t("pickupMethods.title")}
            </div>
            <div className={styles.methods_wrapper}>
                <div className={styles.item}>
                    <img src={pickupPersonallyIcon} alt={"personally"}/>
                    <div className={styles.label}>{t("pickupMethods.personally")}</div>
                </div>
                <div className={styles.item}>
                    <img src={glovoIcon} alt={"glovo"} />
                </div>
                <div className={styles.item}>
                    <img src={uberEatsIcon} alt={"uber-eats"} />
                </div>
            </div>
        </div>
    )
}
