import React, { useEffect, useState } from 'react';
import styles from './ProductsPage.module.scss';
import ProductsImageCarousel from './components/product-image-carousel/ProductsImageCarousel';
import ProductsCategories from './components/products-categories/ProductsCategories';
import ProductsCatalogue from './components/products-catalogue/ProductsCatalogue';
import { Category } from './components/products-categories/models/Category.model';
import { useTranslation } from 'react-i18next';
import ProducersList from './components/producers-list/ProducersList';
import ProductsOfADay from './components/products-of-a-day/ProductsOfADay';
import NewestProducts from './components/newest-products/NewestProducts';
import MostPurchasedProducts from './components/most-purchased-products/MostPurchasedProducts';
import Search from './components/search/Search';
import { usePluginStore } from 'react-pluggable';
import ProductsSearchedView from './components/products-searched-view/ProductsSearchedView';
import CartPanel from '../../shared/cart-panel/CartPanel';

const basketIcon = require("../../assets/icons/koszyk.svg").default;
const bagIcon = require("../../assets/icons/torba.svg").default;
const carIcon = require("../../assets/icons/delivery.svg").default;
const appleIcon = require("../../assets/icons/smacznego.svg").default;
const magnifierIcon = require("../../assets/icons/magnifier_white.svg").default;

export default function ProductsPage(props: any) {

    const { t, i18n } = useTranslation('translation');
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [isSearched, setIsSearched] = useState<boolean>(false);
    const [openCartPanel, setOpenCartPanel] = useState<boolean>(false);

    const handleCategorySelect = (category: Category): void => {
        setSelectedCategory(category);
        setIsSearched(false);
    };

    const handleCartItemsChange = () => {
        props.onCartItemsChange();
        handleToggleCartPanel(true);
    }

    const handleSearchedClicked = () => {
        setIsSearched(true);
    }
    const handleToggleCartPanel = (value: boolean) => {
        setOpenCartPanel(value);
    }

    return (
        <div className={styles.main_container}>
            <div className={styles.image_carousel}>
                <ProductsImageCarousel />
            </div>
            {!selectedCategory &&
                <div className={styles.info_container}>
                    <a href='/selling-sessions' className={styles.item}>
                        <img src={basketIcon} alt="Basket" />
                        <div className={styles.item_description}>
                            <div className={styles.top_part}>{t("productsPage.basketTopInfo")}</div>
                            <div className={styles.bottom_part}>{t("productsPage.basketBottomInfo")}</div>
                        </div>
                    </a>
                    <a href='/producers' className={styles.item}>
                        <img src={bagIcon} alt="Bag" />
                        <div className={styles.item_description}>
                            <div className={styles.top_part}>{t("productsPage.bagTopInfo")}</div>
                            <div className={styles.bottom_part}>{t("productsPage.bagBottomInfo")}</div>
                        </div>
                    </a>
                    <a href='/pickup-methods' className={styles.item}>
                        <img src={carIcon} alt="Car" />
                        <div className={styles.item_description}>
                            <div className={styles.top_part}>{t("productsPage.carTopInfo")}</div>
                            <div className={styles.bottom_part}>{t("productsPage.carBottomInfo")}</div>
                        </div>
                    </a>
                    <a href='/contact' className={styles.item}>
                        <img src={appleIcon} alt="Apple" />
                        <div className={styles.item_description}>
                            <div className={styles.top_part}>{t("productsPage.appleTopInfo")}</div>
                            <div className={styles.bottom_part}>{t("productsPage.appleBottomInfo")}</div>
                        </div>
                    </a>
                </div>
            }

            <div className={`${styles.categories_and_products} ${selectedCategory ? `${styles.product_list}` : ''}`}>
                <ProductsCategories isSearched={isSearched} onSelectCategory={handleCategorySelect} />
                {
                    !isSearched ?
                        selectedCategory ?
                            <ProductsCatalogue category={selectedCategory} onCartItemsChange={handleCartItemsChange} searchedClicked={handleSearchedClicked} />
                            :
                            <div className={styles.products_container}>
                                <Search onCartItemsChange={handleCartItemsChange} searchedClicked={handleSearchedClicked} />
                                <MostPurchasedProducts onCartItemsChange={handleCartItemsChange} />
                                <NewestProducts onCartItemsChange={handleCartItemsChange} />
                            </div>
                        :
                        <div className={styles.products_searched_container}>
                            <ProductsSearchedView onCartItemsChange={handleCartItemsChange} />
                        </div>

                }
            </div>
            {(!selectedCategory && !isSearched) &&
                <div className={styles.producers_and_promo}>
                    <div className={styles.producers_list}>
                        <ProducersList />
                    </div>
                    <div className={styles.products_of_a_day}>
                        <ProductsOfADay onCartItemsChange={handleCartItemsChange}></ProductsOfADay>
                    </div>
                </div>
            }
            {openCartPanel &&
                <CartPanel toggleOpenCartPanel={handleToggleCartPanel}></CartPanel>
            }
        </div>
    );
}
