import { useEffect, useRef, useState } from "react";
import styles from './OtherDistributorProducts.module.scss';
import ProductCard from "../../../shared/product-card/ProductCard";
import { Product } from "../../products-page/models";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { getOtherDistributorProducts } from "../services";
import "swiper/css";
import "swiper/css/pagination";
import "./swiper/swiper.scss"
import { useTranslation } from "react-i18next";
import { ProductPaginated } from "../../products-page/components/products-searched-view/models";
import ButtonArrowBack from "../../../shared/ui-elements/button-with-arrow-back/ButtonArrowBack";
import ButtonArrowForward from "../../../shared/ui-elements/button-with-arrow-forward/ButtonArrowForward";


export default function OtherDistributorProducts(props: any) {

    const [productObject, setProductObject] = useState<ProductPaginated>();
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const prevRef = useRef<any>(null);
    const nextRef = useRef<any>(null);
    const { t, i18n } = useTranslation('translation');
    const slides = productObject?.items || [];
    const totalSlides = slides.length;

    const handleCartItemsChange = () => {
        props.onCartItemsChange();
    };

    const handleSwiperChange = (swiper: any) => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
    };


    useEffect(() => {
        if (props.productsObject) {
            console.log(props.productsObject)
            setProductObject(props.productsObject)
        }
        if (props.productId) {
            getOtherDistributorProducts(props.productId).then((response) => {
                console.log(response.data)
                setProductObject(response.data);
            });
        }
    }, [props.productId, props.productsObject]);


    return (
        <div className={`${styles.main_container} ${!props.productId && styles.border_top_none}`}>
            <div className={styles.header_container}>
                {props.productId ? (
                    <p className={styles.header}>
                        {t("productDetails.otherDistributorProducts")}
                    </p>
                ) : (
                    <p className={styles.name}>
                        {t("producerDetails.cards.producerProducts")}
                    </p>
                )}
            </div>
            <div className={styles.cards_container}>
                <div className={styles.arrow_back_container} ref={prevRef}>
                    <ButtonArrowBack isClickable={!isBeginning} />
                </div>
                <Swiper
                    className={styles.my_swiper}
                    navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }}
                    pagination={{
                        clickable: true
                    }}
                    modules={[Navigation, Pagination]}
                    slidesPerGroup={Math.min(totalSlides, 4)}
                    slidesPerView={Math.min(totalSlides, 4)}
                    spaceBetween={15}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            slidesPerGroup: 1
                        },
                        800: {
                            slidesPerView: 2,
                            slidesPerGroup: 2
                        },
                        1300: {
                            slidesPerView: 3,
                            slidesPerGroup: 3
                        },
                        1710: {
                            slidesPerView: 4,
                            slidesPerGroup: 4
                        },
                    }}
                    onSlideChangeTransitionEnd={handleSwiperChange}
                >
                    {slides.map((product: Product, index: number) => (
                        <SwiperSlide key={index} className={styles.card_container}>
                            <ProductCard
                                product={product}
                                onCartItemsChange={handleCartItemsChange}
                                key={product.id}
                                otherProductComponent={true}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={styles.arrow_forward_container} ref={nextRef}>
                    <ButtonArrowForward isClickable={!isEnd} />
                </div>
            </div>
        </div>
    );
}
