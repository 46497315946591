import axios from 'axios';
import { ChangePasswordDto } from '../models/change-password.model';
import { ChangeEmailDto } from '../models/change-email.model';

export async function getUserDetails() {
    return axios.get("UserDetails");
}

export async function changePassword(dto: ChangePasswordDto) {
    return axios.post('Auth/ChangePassword', dto);
}

export async function changeEmail(dto : ChangeEmailDto) {
    return axios.post('Auth/ChangeEmail', dto);
}