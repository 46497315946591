import { useEffect } from 'react';
import { OrderItem } from '../../../order/models';
import styles from './OrderConfirmationProducts.module.scss';
import { OrderDetailsModel } from '../../../order/models/order-details.model';
import OrderDetails from '../../../client-panel/components/order-details/OrderDetails';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import uuid from 'react-uuid';

interface OrderConfirmationProductsProps {
    orderDetails?: OrderDetailsModel
}

export default function OrderConfirmationProducts({ orderDetails }: OrderConfirmationProductsProps) {

    const currency = useSelector((state: RootState) => state.currency);


    return (
        <div className={styles.container}>
            <div className={styles.header}>ZAWARTOŚĆ ZAMÓWIENIA</div>
            <div className={styles.table_container}>
                <div className={styles.row}>
                    <div className={`${styles.description_column} ${styles.product_column}`}>
                        PRODUKT
                    </div>
                    <div className={` ${styles.description_column}`}>
                        CENA
                    </div>
                    <div className={` ${styles.description_column}`}>
                        J. M.
                    </div>
                    <div className={`${styles.description_column}`}>
                        ILOŚĆ
                    </div>
                    <div className={` ${styles.description_column}`}>
                        RAZEM
                    </div>
                </div>
                {orderDetails?.order.items && orderDetails?.order.items.map((p, index) => (
                    <div key={uuid()} className={styles.row}>
                        <div className={`${styles.column} ${styles.product_column}`}>
                            {p.productName}
                        </div>
                        <div className={styles.column}>
                            {(p.netPrice + p.netPrice * p.vatRate).toFixed(2)}{currency}
                        </div>
                        <div className={styles.column}>
                            {p.saleUnitName}
                        </div>
                        <div className={styles.column}>
                            {p.quantity}
                        </div>
                        <div className={styles.column}>
                            {Number(p.quantity * (p.netPrice + p.netPrice * p.vatRate)).toFixed(2)} {currency}
                        </div>
                    </div>

                ))}

            </div>
        </div>
    )
}